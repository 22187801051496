


























































































































































































































































































































































































































































































































































































































































import { FormBlock, Scrollbar } from '@/app/components';
import { useAxios } from '@/app/composable';
import store from '@/app/store';
import { AccessLevel } from '@/modules/access-policy/constants/access-levels.constants';
import { GeneralPolicy } from '@/modules/access-policy/models';
import { Ref, computed, defineComponent, ref, watch } from '@vue/composition-api';
import { OrbitSpinner } from 'epic-spinners';
import * as R from 'ramda';
import { AccessPolicy } from '../../access-policy/components';
import { AssetsAPI } from '../api';
import { useAssetMetadata } from '../composable/asset-metadata';
import { useAssetStatus } from '../composable/asset-status';
import { AccrualPeriodicityInterval, AssetType, StatusCode } from '../constants';

export default defineComponent({
    name: 'EditAsset',
    metaInfo() {
        let assetName = (this as any).assetDisplayName ? `: ${(this as any).assetDisplayName}` : null;
        if (!assetName) assetName = (this as any).asset ? `: ${(this as any).asset.name}` : '';

        return {
            title: `Edit ${(this as any).isResult ? 'Result' : 'Asset'} Details${assetName}`,
        };
    },
    props: {
        id: {
            type: [Number, String],
            required: false,
        },
        backTo: {
            type: String,
            default: 'assets',
        },
        queryParams: {
            type: String,
            default: '{}',
        },
    },
    components: {
        OrbitSpinner,
        FormBlock,
        AccessPolicy,
        Scrollbar,
    },
    setup(props, { root }) {
        const assetDisplayName = ref<string>('');

        const id: number | null = props.id ? parseInt(`${props.id}`, 10) : null;

        const previousAccessLevel = ref<string | null>(null);
        const previousAccessPolicies = ref<any>([]);
        const previousLicenseMetadata = ref<any>(null);

        const domain = ref<any>(null);
        const { exec, loading, error } = useAxios(true);
        const {
            asset,
            accessLevel,
            copyrightOwner,
            domainsIdToUID,
            domainsOptions,
            spatialIDfieldName,
            temporalFields,
            spatialFields,
            getGeneralSchema,
            getTemporalResolutionSchema,
            getSpatialResolutionSchema,
            getAccrualPeriodicitySchema,
            getLicensingSchema,
            initAsset,
            mergeAsset,
            accessLevelOptions,
            assetTemporalCoverageOptions,
            assetAccrualPeriodicityOptions,
            assetSpatialCoverageOptions,
            checkLicense,
            locationOptions,
            assetType,
            fetchSpatialIDFields,
        } = useAssetMetadata();

        const isResult = computed(() => assetType.value === AssetType.Result);

        const validForms = ref<any>({
            general: false,
            generalMetadata: false,
            accrualPeriodicity: false,
            temporalCoverage: false,
            spatialCoverage: false,
            temporalResolution: false,
            spatialResolution: false,
            accessLevelCopyrightOwner: false,
            licensing: false,
        });
        const user = computed(() => store.state.auth.user);

        const status: Ref<StatusCode | undefined> = computed((): StatusCode | undefined => asset.value?.status);

        const { label: assetStatusLabel, colour: assetStatusClass } = useAssetStatus(status);

        const metadata = { general: true, distribution: true, extent: true, licensing: true };
        const emptyAsset = initAsset(metadata);
        const accessPolicies = ref<any>({ generalPolicy: GeneralPolicy.DENY_ALL, policies: [] });

        const saveClicked = ref<boolean>(false);
        const contentRef = ref<any>(null);
        const forbiddenEditAccess = ref<boolean>(false);

        const timePeriodExists = computed(() => !!asset.value?.metadata?.extent?.temporalCoverage?.timePeriod);
        const timePeriodMinExists = computed(
            () => timePeriodExists.value && !!asset.value.metadata.extent.temporalCoverage.timePeriod[0].min,
        );
        const timePeriodMaxExists = computed(
            () => timePeriodExists.value && !!asset.value.metadata.extent.temporalCoverage.timePeriod[0].max,
        );

        const startDisabledDates = computed(() => {
            if (timePeriodMaxExists.value)
                return {
                    from: new Date(asset.value.metadata.extent.temporalCoverage.timePeriod[0].max),
                };
            return { from: null };
        });

        const endDisabledDates = computed(() => {
            if (timePeriodMinExists.value)
                return {
                    to: new Date(asset.value.metadata.extent.temporalCoverage.timePeriod[0].min),
                };
            return { to: null };
        });

        // Load asset (if an id is provided)
        if (id) {
            exec(AssetsAPI.getAsset(id))
                .then((res: any) => {
                    if (res.data.createdBy.id === user.value.id) {
                        mergeAsset(emptyAsset, res.data);
                        assetDisplayName.value = R.clone(asset.value.name);
                        previousAccessLevel.value = res.data.accessLevel;
                        previousAccessPolicies.value = res.data.policies;
                        previousLicenseMetadata.value = res.data.metadata?.license;
                    } else {
                        forbiddenEditAccess.value = true;
                        (root as any).$toastr.e(
                            'You do not have access to edit the specific asset.',
                            'Access Forbidden!',
                        );
                        root.$router.push({ name: 'assets', query: JSON.parse(props.queryParams) });
                    }
                })
                .catch((e) => {
                    if (e.response.status !== 403) throw e; // if the error is not for forbidden access, then send to sentry
                    (root as any).$toastr.e('You do not have access to edit the specific asset.', 'Access Forbidden!');
                    root.$router.push({ name: 'assets', query: JSON.parse(props.queryParams) });
                });
        }

        // Methods
        const cancel = () => {
            if (props.backTo === 'assets:view')
                root.$router.push({ name: 'assets:view', params: { id: `${id}`, queryParams: props.queryParams } });
            else root.$router.push({ name: 'assets', query: JSON.parse(props.queryParams) });
        };

        // TODO: Hide this for now
        // const getAccessPoliciesJSON = () => {
        //     const json = [];

        //     if (accessLevel.value === AccessLevel.OrganisationLevel) {
        //         const policy: ExceptionPolicy = new ExceptionPolicy(true, [
        //             new IndividualCondition(Field.ORGANISATION_ID, Operant.EQUALS, [
        //                 new ConditionValue(user.value.organisationId, Field.ORGANISATION_ID.key),
        //             ]),
        //         ]);
        //         json.push(policy.toJSON());
        //     } else if (accessLevel.value === AccessLevel.SelectiveSharing) {
        //         const policy: ExceptionPolicy = new ExceptionPolicy(accessPolicies.value.generalPolicy.allow, [
        //             new IndividualCondition(Field.ORGANISATION_ID, Operant.EQUALS, [
        //                 new ConditionValue(user.value.organisationId, Field.ORGANISATION_ID.key),
        //             ]),
        //         ]);
        //         json.push(policy.toJSON());
        //         accessPolicies.value.policies.forEach((exceptionPolicy: ExceptionPolicy) => {
        //             json.push(exceptionPolicy.toJSON());
        //         });
        //     } else
        //         accessPolicies.value.policies.forEach((policy: ExceptionPolicy) => {
        //             json.push(policy.toJSON());
        //         });

        //     return json;
        // };

        const selectedDomain = computed(() => {
            if (
                (!asset.value?.structure?.domain || !asset.value?.structure?.domain.uid) &&
                domain.value &&
                domainsIdToUID.value
            ) {
                const keys = Object.keys(domainsOptions.value);
                const keysUid = Object.keys(domainsIdToUID.value);
                if (keys.includes(domain.value) && keysUid.includes(domain.value)) {
                    const name = domainsOptions.value[domain.value];
                    const uid = domainsIdToUID.value[domain.value];
                    return { ...uid, name };
                }
            }
            return null;
        });

        const saveChanges = async () => {
            if (selectedDomain.value && asset.value.structure) asset.value.structure.domain = selectedDomain.value;

            const clonedAsset = R.clone(asset.value);

            // distribution metadata
            if (metadata.distribution && asset.value.metadata.distribution) {
                if (asset.value.metadata.distribution.unit === 'Not applicable')
                    asset.value.metadata.distribution.value = null;
            }

            // extent metadata
            if (metadata.extent && asset.value.metadata.extent) {
                switch (asset.value.metadata.extent.temporalCoverage.unit) {
                    case 'Not applicable':
                        asset.value.metadata.extent.temporalCoverage = {
                            type: null,
                            field: null,
                            value: null,
                            min: null,
                            max: null,
                            unit: asset.value.metadata.extent.temporalCoverage.unit,
                        };
                        break;
                    case 'Time Period':
                        asset.value.metadata.extent.temporalCoverage = {
                            type: 'custom',
                            field: null,
                            value: null,
                            min: asset.value.metadata.extent.temporalCoverage.timePeriod[0].min,
                            max: asset.value.metadata.extent.temporalCoverage.timePeriod[0].max,
                            unit: asset.value.metadata.extent.temporalCoverage.unit,
                        };
                        break;
                    case 'Single Date':
                        asset.value.metadata.extent.temporalCoverage = {
                            type: 'custom',
                            field: null,
                            value: null,
                            min: asset.value.metadata.extent.temporalCoverage.min,
                            max: asset.value.metadata.extent.temporalCoverage.min,
                            unit: asset.value.metadata.extent.temporalCoverage.unit,
                        };
                        break;
                    case 'Calculated based on data':
                        if (temporalFields.value.length)
                            for (let i = 0; i < temporalFields.value.length; i++) {
                                if (
                                    temporalFields.value[i].value === asset.value.metadata.extent.temporalCoverage.field
                                )
                                    asset.value.metadata.extent.temporalCoverage.field = {
                                        uid: asset.value.metadata.extent.temporalCoverage.field,
                                        name: temporalFields.value[i].label.replaceAll(' > ', '__'),
                                    };
                            }
                        asset.value.metadata.extent.temporalCoverage = {
                            type: 'field',
                            field: asset.value.metadata.extent.temporalCoverage.field,
                            value: null,
                            min: null,
                            max: null,
                            unit: asset.value.metadata.extent.temporalCoverage.unit,
                        };
                        break;
                    default:
                        asset.value.metadata.extent.temporalCoverage = {
                            type: 'custom',
                            field: null,
                            value: Number(asset.value.metadata.extent.temporalCoverage.value),
                            min: null,
                            max: null,
                            unit: asset.value.metadata.extent.temporalCoverage.unit,
                        };
                }
                switch (asset.value.metadata.extent.spatialCoverage.unit) {
                    case 'Not applicable':
                        asset.value.metadata.extent.spatialCoverage = {
                            type: null,
                            field: null,
                            value: null,
                            values: [],
                            coordinates: null,
                            unit: asset.value.metadata.extent.spatialCoverage.unit,
                        };
                        break;
                    case 'Specific Continent/Countries':
                        asset.value.metadata.extent.spatialCoverage = {
                            type: 'custom',
                            field: null,
                            value: null,
                            values: asset.value.metadata.extent.spatialCoverage.values,
                            coordinates: null,
                            unit: asset.value.metadata.extent.spatialCoverage.unit,
                        };
                        break;
                    case 'Exact Location':
                        asset.value.metadata.extent.spatialCoverage = {
                            type: 'custom',
                            field: null,
                            value: null,
                            values: [],
                            coordinates: {
                                lat: Number(asset.value.metadata.extent.spatialCoverage.exactLocation[0].lat),
                                lon: Number(asset.value.metadata.extent.spatialCoverage.exactLocation[0].lon),
                            },
                            unit: asset.value.metadata.extent.spatialCoverage.unit,
                        };
                        break;
                    case 'Calculated based on data':
                        if (spatialFields.value.length)
                            for (let i = 0; i < spatialFields.value.length; i++) {
                                if (spatialFields.value[i].value === asset.value.metadata.extent.spatialCoverage.field)
                                    asset.value.metadata.extent.spatialCoverage.field = {
                                        uid: asset.value.metadata.extent.spatialCoverage.field,
                                        name: spatialFields.value[i].label.replaceAll(' > ', '__'),
                                    };
                            }
                        asset.value.metadata.extent.spatialCoverage = {
                            type: 'field',
                            field: asset.value.metadata.extent.spatialCoverage.field,
                            value: null,
                            values: [],
                            coordinates: null,
                            unit: asset.value.metadata.extent.spatialCoverage.unit,
                        };
                        break;
                    default:
                        asset.value.metadata.extent.spatialCoverage = {
                            type: 'custom',
                            field: spatialIDfieldName,
                            value: asset.value.metadata.extent.spatialCoverage.value,
                            values: [],
                            coordinates: null,
                            unit: asset.value.metadata.extent.spatialCoverage.unit,
                        };
                }
                if (asset.value.metadata.extent.temporalResolution.value)
                    asset.value.metadata.extent.temporalResolution.value = Number(
                        asset.value.metadata.extent.temporalResolution.value,
                    );
            }
            // licensing metadata
            if (metadata.licensing && asset.value.metadata.license)
                asset.value.metadata.license.copyrightOwner = copyrightOwner.value;

            // keep previous access level and policies before updates
            previousAccessLevel.value = R.clone(asset.value.accessLevel);
            previousAccessPolicies.value = R.clone(asset.value.policies);

            asset.value.accessLevel = accessLevel.value;
            // asset.value.policies = getAccessPoliciesJSON();
            const policyChanges = {
                addPolicies: accessPolicies.value.policies?.add,
                removePolicies: accessPolicies.value.policies?.remove,
            };
            if (id)
                try {
                    await exec(AssetsAPI.updateAsset(id, { ...asset.value, ...policyChanges } as any));
                    root.$router.push({ name: 'assets:view', params: { id: `${id}`, queryParams: props.queryParams } });
                } catch (e) {
                    error.value = e;
                    asset.value = clonedAsset;
                    // on error reset asset access level and policies
                    asset.value.accessLevel = previousAccessLevel.value as AccessLevel;
                    accessLevel.value = previousAccessLevel.value;
                    asset.value.policies = previousAccessPolicies.value;
                    if (metadata.licensing) asset.value.metadata.license = previousLicenseMetadata.value;
                }
        };

        const save = computed(() => {
            if (
                !validForms.value.general ||
                (metadata.general && !validForms.value.generalMetadata) ||
                (metadata.extent &&
                    (!validForms.value.temporalCoverage ||
                        !validForms.value.spatialCoverage ||
                        !validForms.value.temporalResolution ||
                        !validForms.value.spatialResolution)) ||
                (metadata.distribution && !validForms.value.accrualPeriodicity) ||
                (metadata.licensing && (!validForms.value.accessLevelCopyrightOwner || !validForms.value.licensing))
            )
                return false;
            return true;
        });

        const submitForms = () => {
            saveClicked.value = true;
            validForms.value = {
                general: false,
                generalMetadata: false,
                accrualPeriodicity: false,
                temporalCoverage: false,
                spatialCoverage: false,
                temporalResolution: false,
                spatialResolution: false,
                accessLevelCopyrightOwner: false,
                licensing: false,
            };
            (root as any).$formulate.submit('general');
            if (metadata.general) (root as any).$formulate.submit('generalMetadata');
            if (metadata.extent) {
                (root as any).$formulate.submit('temporalCoverage');
                (root as any).$formulate.submit('spatialCoverage');
                (root as any).$formulate.submit('temporalResolution');
                (root as any).$formulate.submit('spatialResolution');
            }
            if (metadata.distribution) {
                (root as any).$formulate.submit('accrualPeriodicity');
            }
            if (metadata.licensing) {
                validForms.value.accessLevelCopyrightOwner = false;
                (root as any).$formulate.submit('accessLevelCopyrightOwner');
                if (
                    !accessLevel.value ||
                    accessLevel.value === AccessLevel.Private ||
                    accessLevel.value === AccessLevel.OrganisationLevel
                )
                    validForms.value.licensing = true;
                else {
                    validForms.value.licensing = false;
                    (root as any).$formulate.submit('licensing');
                }
            }
        };

        const formSubmitted = (name: string) => {
            if (saveClicked.value) {
                validForms.value[name] = true;
                if (save.value) saveChanges();
                else contentRef.value.scrollTo({ top: 0, behavior: 'smooth' });
            }
        };

        const addSpatialCoverage = (value: any) => {
            asset.value.metadata.extent.spatialCoverage.values = value;
        };

        const resetLicenseAndPricing = (level: any) => {
            if (level)
                if (level === AccessLevel.Private) {
                    asset.value.metadata.license.license = null;
                    asset.value.metadata.license.copyrightOwner = null;
                    asset.value.metadata.license.link = null;
                } else if (
                    level === AccessLevel.SelectiveSharing &&
                    asset.value.metadata.license.license !== 'Custom'
                ) {
                    asset.value.metadata.license.license = 'Custom';
                    checkLicense({ id: 'Custom', label: 'Custom' });
                }
        };

        watch(
            () => asset.value && asset.value.metadata.license && accessLevel.value,
            (level) => resetLicenseAndPricing(level),
        );

        const customError = computed(() => {
            if (error.value?.response?.status === 403 || forbiddenEditAccess.value)
                return { title: 'Access Forbidden!', message: 'You do not have access to edit the specific asset.' };
            if (
                error.value?.response?.status === 400 &&
                R.hasPath(['response', 'data', 'validProvenance'], error.value)
            ) {
                const { validProvenance, invalidWorkflows } = error.value.response?.data;
                if (!validProvenance || invalidWorkflows?.length >= 0)
                    return {
                        title: 'Access Policy Restriction!',
                        message:
                            'Updating the access level of the specific asset is not allowed at the moment as it’s used by other pipelines in your organisation.',
                        validProvenance,
                        invalidWorkflows,
                    };
            }
            return { title: 'An error has occurred!', message: error.value?.response?.data?.message };
        });

        const assetTitleValidations = computed<{ regex: string; message: string }>(() => {
            if (assetType.value === AssetType.Model)
                return {
                    regex: '/^[A-Za-z0-9-_]*[a-zA-Z]+[A-Za-z0-9-_]*$/',
                    message:
                        'Title must contain only alphanumeric characters, dashes, underscores and at least one letter.',
                };
            return {
                regex: '/^[A-Za-z0-9-_ ]*[a-zA-Z]+[A-Za-z0-9-_ ]*$/',
                message:
                    'Title must contain only alphanumeric characters, dashes, underscores, spaces and at least one letter.',
            };
        });

        /**
         * handles the accrual periodicity radio button changes
         */
        const hasAccrualPeriodicity = computed<string | undefined>({
            get: () => {
                if (R.isNil(asset.value?.metadata?.distribution?.accrualPeriodicity?.unit)) return undefined;
                // if accrual periodicity is set and is not NA then
                // this returns "yes"
                return asset.value.metadata.distribution.accrualPeriodicity.unit !== AccrualPeriodicityInterval.NA
                    ? 'yes'
                    : 'no';
            },
            set: (newSelection: string | undefined) => {
                // if the selection is yes and accrual periodicity is NA then we default to 1 day
                // if it's set to no then we set the value to null and the unit to NA
                if (
                    newSelection === 'yes' &&
                    (R.isNil(asset.value.metadata.distribution.accrualPeriodicity.unit) ||
                        asset.value.metadata.distribution.accrualPeriodicity.unit === AccrualPeriodicityInterval.NA)
                )
                    asset.value.metadata.distribution.accrualPeriodicity = {
                        value: '1',
                        unit: AccrualPeriodicityInterval.Day,
                    };
                else
                    asset.value.metadata.distribution.accrualPeriodicity = {
                        value: null,
                        unit: AccrualPeriodicityInterval.NA,
                    };
            },
        });

        watch(
            () => selectedDomain.value,
            (domainValue, oldDomainValue) => {
                if (domainValue && domainValue !== oldDomainValue) {
                    asset.value.metadata.extent.spatialCoverage.unit = null;
                    fetchSpatialIDFields(domainValue);
                }
            },
        );

        return {
            contentRef,
            domain,
            accessLevel,
            copyrightOwner,
            domainsOptions,
            cancel,
            error,
            asset,
            loading,
            metadata,
            getGeneralSchema,
            getTemporalResolutionSchema,
            getSpatialResolutionSchema,
            getLicensingSchema,
            accessLevelOptions,
            assetTemporalCoverageOptions,
            assetSpatialCoverageOptions,
            submitForms,
            formSubmitted,
            accessPolicies,
            user,
            validForms,
            save,
            saveClicked,
            temporalFields,
            spatialFields,
            locationOptions,
            addSpatialCoverage,
            startDisabledDates,
            endDisabledDates,
            assetStatusLabel,
            assetStatusClass,
            assetDisplayName,
            isResult,
            customError,
            forbiddenEditAccess,
            AccessLevel,
            previousAccessLevel,
            selectedDomain,
            assetTitleValidations,
            getAccrualPeriodicitySchema,
            assetAccrualPeriodicityOptions,
            AccrualPeriodicityInterval,
            hasAccrualPeriodicity,
            timePeriodMinExists,
            timePeriodMaxExists,
        };
    },
});
